<template>
    <div id="firmaekle">
        <b-container>
            <b-row>
                <b-col sm="12">
                    <b-form @submit.prevent="ekle" class="text-left mt-5">
                        <b-form-group
                            id="input-group-5"
                            label="Plaka:"
                            label-for="input-5">
                            <b-form-input
                            id="input-5"
                            v-model="form.user_plaka"
                            type="text"
                            placeholder="Kullanıcının plakasını girin."
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            id="input-group-1"
                            label="Ad Soyad / Ünvan:"
                            label-for="input-1">
                            <b-form-input
                            id="input-1"
                            v-model="form.user_adsoyad"
                            required
                            type="text"
                            placeholder="Toplayıcının adını soyadını girin."
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-2"
                            label="Tel: (zorunlu)"
                            label-for="input-2">
                            <b-form-input
                            id="input-2"
                            v-model="form.user_tel"
                            required
                            type="number"
                            placeholder="Telefon numarasını girin."
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-4"
                            label="Şifre: (zorunlu)"
                            label-for="input-4">
                            <b-form-input
                            id="input-4"
                            v-model="form.user_sifre"
                            placeholder="Şifre girin."
                            ></b-form-input>
                        </b-form-group>

                        
                        <b-button block v-if="!$route.params.id" type="submit" variant="primary" class="float-right mb-5">Ekle</b-button>
                        <b-button block v-if="$route.params.id" type="submit" variant="primary" class="float-right mb-5">Düzenle</b-button>
                    </b-form>
                </b-col>    
            </b-row>
        </b-container>
    </div>
</template>

<script>

export default {
    data() {
        return{
            //form: [],
        }
    },

    methods : {
        ekle(){
            if(this.$route.params.id){
                this.$store.dispatch("userEdit", {
                    user_adsoyad : this.form.user_adsoyad,
                    user_tel : this.form.user_tel,
                    user_eposta : this.form.user_eposta,
                    user_sifre : this.form.user_sifre,
                    user_plaka : this.form.user_plaka,
                    user_id : this.$route.params.id
                })
                .then(r => {
                    if(r.status){
                        this.$router.push("/kullanicilar")
                    }
                })
            }else{
                console.log(this.form);
                this.$store.dispatch("userAdd", {
                    user_adsoyad : this.form.user_adsoyad,
                    user_tel : this.form.user_tel,
                    user_eposta : 'info@mensangeridonusum.com.tr',
                    user_plaka : this.form.user_plaka,
                    user_sifre : this.form.user_sifre,
                    user_rutbe: 3,
                    user_durum: 1,
                })
                .then(r => {
                    if(r.status){
                        this.$router.push("/kullanicilar")
                    }
                })
            }
            
        }
    },

    computed: {
        form(){
            var deger = [];
            if(this.$route.params.id){
                deger = this.$store.state.users.filter(c => c.user_id == this.$route.params.id)[0]
            }
            return deger;
        }
    }
}
</script>